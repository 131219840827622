<template>
    <div class="row">
        <!-- label-->
        <div class="col-10" style="align-self: end;">
            <h6><strong>{{titulo}}</strong></h6>
        </div>

        <b-button @click="modalAdicionar()" variant="link" style="text-decoration: none"> 
        <b-icon icon="plus-square"></b-icon> Adicionar</b-button>

        <div>
            <ul v-if="value">
                <li v-for="(item,id) in value" :key="item.id">
                    <strong>Denominação: </strong>{{item.denominacao}} | 
                    <strong>Carga horária: </strong>{{item.carga_horaria}}
                    <b-button v-b-modal="'modal-edit-avaliacao'" @click="modalEdit(id)" variant="link" style="text-decoration: none"> 
                    <b-icon icon="pencil-square"></b-icon>Editar</b-button>
                </li>
            </ul>
            <div v-else>
                <p class="sem-texto">
                    Não há item cadastrado. Clique em ADICIONAR para inserir.
                </p>
            </div>
        </div>

        <b-modal size="lg" :id="'modal-'+code" :title="titulo" @hidden="resetModal">
            {{valor_provisorio}} | {{id_selecionado}}
            <b-form-group label="denominacao:" :label-for="'modal-input-'+code+'denominacao'">
                <b-form-input :id="'modal-input-'+code+'denominacao'" v-model="valor_provisorio.denominacao"></b-form-input>
            </b-form-group>

            <b-form-group label="Carga horária:" :label-for="'modal-input-'+code+'carga_horaria'">
                <b-form-input :id="'modal-input-'+code+'carga_horaria'" v-model="valor_provisorio.carga_horaria"></b-form-input>
            </b-form-group>  

            <template v-if="modal_editar" #modal-footer>
                <b-button size="sm" variant="danger" @click="deletar()">
                    Deletar
                </b-button>
                <b-button size="sm" variant="primary" @click="edit()">
                    Salvar
                </b-button>  
            </template>
            <template v-else #modal-footer>
                <b-button size="sm" variant="primary" @click="Add()">
                    Adicionar
                </b-button>  
            </template>
        </b-modal>
    
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    name: 'addTopicItem',
    props: ['titulo', 'code', 'value', 'id_curso', 'id_campus', 'forms', 'type', 'showMode'],
    data() {
        return {
            valor_provisorio: {
                denominacao: '',
                carga_horaria: ''
            },
            modal_editar: false,
            id_selecionado: ''
        }
    },
    methods: {
        resetModal() {
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio = {denominacao: '', carga_horaria: ''}
        },

        modalAdicionar(){
            this.modal_editar = false
            this.$bvModal.show('modal-' + this.code)
        },

        atualizaFirebase(){
            let doc_name
            let path_item
            let colection

            if(this.forms == 'uninove_geral'){
                console.log('Alterando formulário Geral UNINOVE')
                doc_name = 'forms_geral'
                path_item = {[`documento_completo.${this.code}`]: this.valor_provisorio}
                colection = '/nadir'
            } else if(this.forms == 'campus_especifico') {
                console.log(`Alterando dados do campus ${this.id_campus}`)
                doc_name = this.id_campus
                path_item = {[`${this.code}`]: this.valor_provisorio}
                colection = '/campus_uninove'
            } else if(this.forms == 'itens_gerais_curso_especifico') {
                console.log(`Alterando dados do curso ${this.id_curso}`)
                doc_name = this.id_curso
                path_item = {[`${this.code}`]: this.value}
                colection = '/nadir'
            } else {
                console.log(`Alterando formulário específico de código ${this.id_curso}`)
                doc_name = this.id_curso
                path_item = {[`formulario_especifico.${this.code}`]: this.valor_provisorio}
                colection = '/nadir'
            }
            console.log('Doc_name', doc_name, colection)
            console.log('path', path_item)
            console.log('this.value', this.value)


            db.collection(colection).doc(doc_name)
            .update(path_item)
            .then(() => {
                alert('Alterações salvas com sucesso')
                console.log("Document successfully updated!");
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide('modal-'+ this.code)
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        Add(){
            let new_array = {
                denominacao: this.valor_provisorio.denominacao,
                carga_horaria: this.valor_provisorio.carga_horaria
            }

            if (!this.value){
                // Se o array não existir, crie um
                this.value = []
                this.value.push(new_array)
            } else {
                this.value.push(new_array)
            }
            
            this.atualizaFirebase()
            
        },
        
        modalEdit(id_item){
            this.modal_editar = true
            this.id_selecionado = id_item
            this.$bvModal.show('modal-' + this.code)
            this.valor_provisorio.denominacao = this.value[id_item].denominacao
            this.valor_provisorio.carga_horaria = this.value[id_item].carga_horaria
        },

        edit(){
            let id_item = this.id_selecionado
            this.value[id_item].denominacao = this.valor_provisorio.denominacao
            this.value[id_item].carga_horaria = this.valor_provisorio.carga_horaria
            this.atualizaFirebase()
        },
        
        deletar(){
            this.value.splice(this.id_selecionado, 1)
            this.atualizaFirebase()
        }
    }
}
</script>

<style scoped>
ul, ol{
    list-style: auto;
}

input{
    width: -webkit-fill-available;
}

.sem-texto{
    padding: 10px;
    font-style: italic;
    background: #e8e8e8;
}
</style>
