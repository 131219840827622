<template>
    <div class="container mt-4 mb-5" style="text-align: left">
        <div class="row mb-5">
            <div class="col-9">
                <h3>{{nome_curso}} |  PREENCHIMENTO UNINOVE </h3>
            </div>
            <div class="col" style="text-align: right">
                <b-button variant="outline-primary" style="margin-left: 10px;" to="/">Voltar</b-button>
            </div>
        </div>

        <!-- <div class="row">
            <ul>
                <li><strong>Curso: {{nome_curso}}</strong></li>
                <li><strong>Responsável:</strong> {{conteudista}}</li>
                <li><strong>Id_curso:</strong> {{$route.params.id_firebase}}</li>
                <li><strong>Campus_oferta:</strong> {{this.campus_oferta}}</li>
            </ul>
        </div> -->

        <!-- Dados Gerais do Curso Técnico de Nível Médio -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <h2>Dados Gerais do Curso Técnico de Nível Médio</h2>
            <hr>
        </div>

        <!-- Denominação	Curso Técnico em (denominação constante do Catálogo) -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="dg_gerais_curso_tecnico.denominacao" titulo="Denominação Curso Técnico em (denominação constante do Catálogo)" v-model="dg_gerais_curso_tecnico.denominacao" @valorEmitido="log"></itemInput>
        </div>

        <!-- Carga Horária (horas-relógio) -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="dg_gerais_curso_tecnico.carga_horaria_curso" titulo="Carga Horária (horas-relógio)" v-model="dg_gerais_curso_tecnico.carga_horaria_curso" @valorEmitido="log"></itemInput>
        </div>

        <!-- Carga horária do estágio, se houver (horas-relógio) -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="dg_gerais_curso_tecnico.carga_horaria_estagio" titulo="Carga horária do estágio, se houver (horas-relógio)" v-model="dg_gerais_curso_tecnico.carga_horaria_estagio" @valorEmitido="log"></itemInput>
        </div>

        <!-- Modalidade de Oferta (presencial ou a distância)	 -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="dg_gerais_curso_tecnico.modalidade_oferta" titulo="Modalidade de Oferta (presencial ou a distância)" v-model="dg_gerais_curso_tecnico.modalidade_oferta" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- Forma de oferta (concomitante ou subsequente) -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="dg_gerais_curso_tecnico.forma_oferta" titulo="Forma de oferta (concomitante ou subsequente)" v-model="dg_gerais_curso_tecnico.forma_oferta" @valorEmitido="log"></itemInput>
        </div>

        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <h2>Certificações intermediárias</h2>
            <hr>
            <addTopicItem type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="certificacoes_intermediarias" titulo="" v-model="certificacoes_intermediarias" @valorEmitido="log"></addTopicItem>
        </div>
        


        <!-- Organização da Oferta -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <h2>Organização da Oferta</h2>
            <hr>
        </div>

        <!-- Periodicidades de novas matrículas	(anual, semestral ou modular) -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.periodicidade_matriculas" titulo="Periodicidades de novas matrículas	(anual, semestral ou modular)" v-model="organizacao_oferta.periodicidade_matriculas" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- Turnos de funcionamento (se presencial) (matutino, vespertino e/ou noturno) -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.turnos_funcionamento" titulo="Turnos de funcionamento (se presencial) (matutino, vespertino e/ou noturno)" v-model="organizacao_oferta.turnos_funcionamento" @valorEmitido="log"></itemInput>
        </div>

        <!-- Prazo mínimo de integralização	(em anos) -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.prazo_min_integralizacao" titulo="Prazo mínimo de integralização	(em anos)" v-model="organizacao_oferta.prazo_min_integralizacao" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- Prazo máximo de integralização	(em anos) -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.prazo_max_integralizacao" titulo="Prazo máximo de integralização	(em anos)" v-model="organizacao_oferta.prazo_max_integralizacao" @valorEmitido="log"></itemInput>
        </div>

        <!-- Nº de turmas no turno matutino -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.num_turmas_matutino" titulo="Nº de turmas no turno matutino" v-model="organizacao_oferta.num_turmas_matutino" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- Nº de turmas no turno vespertino -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.num_turmas_vespertino" titulo="Nº de turmas no turno vespertino" v-model="organizacao_oferta.num_turmas_vespertino" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- Nº de turmas no turno noturno -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.num_turmas_noturno" titulo="Nº de turmas no turno noturno" v-model="organizacao_oferta.num_turmas_noturno" @valorEmitido="log"></itemInput>
        </div>
                
        <!-- Vagas por turma no turno matutino -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.vagas_por_turmas_matutino" titulo="Vagas por turma no turno matutino" v-model="organizacao_oferta.vagas_por_turmas_matutino" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- Vagas por turma no turno vespertino -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.vagas_por_turmas_vespertino" titulo="Vagas por turma no turno vespertino" v-model="organizacao_oferta.vagas_por_turmas_vespertino" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- Vagas por turma no turno noturno -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.vagas_por_turmas_noturno" titulo="Vagas por turma no turno noturno" v-model="organizacao_oferta.vagas_por_turmas_noturno" @valorEmitido="log"></itemInput>
        </div>
                
        <!-- Vagas totais anuais solicitadas -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <itemInput type="short-text" forms="itens_gerais_curso_especifico" :id_curso="id_curso_atual" code="organizacao_oferta.vagas_totais_anuais_solicitadas" titulo="Vagas totais anuais solicitadas" v-model="organizacao_oferta.vagas_totais_anuais_solicitadas" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- 4.9 Estágio supervisionado (quando houver). -->
        <div v-if="!form_especifico_lab" class="row mb-2 mt-4">
            <h2>4.9 Estágio supervisionado (SOMENTE ENFERMAGEM E RADIOLOGIA)</h2>
            <hr>
        </div>
        
        <!-- 4.9.1 Objetivos.  -->
        <div v-if="!form_especifico_lab" class="row mt-4 mb-4">
            <itemInput code="item_4_9_1" :id_curso="id_curso_atual" titulo="4.9.1 Objetivos" v-model="formulario_especifico.item_4_9_1" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_4_9_1" :id_curso="id_curso_atual" titulo="4.9.1 Objetivos" v-model="formulario_especifico.item_4_9_1" @valorEmitido="log"></richTextInput> -->
        </div>
        
        <!-- 4.9.2 Ambientes de estágio.  -->
        <div v-if="!form_especifico_lab" class="row mt-4 mb-4">
            <itemInput code="item_4_9_2" :id_curso="id_curso_atual" titulo="4.9.2 Ambientes de estágio" v-model="formulario_especifico.item_4_9_2" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_4_9_2" :id_curso="id_curso_atual" titulo="4.9.2 Ambientes de estágio" v-model="formulario_especifico.item_4_9_2" @valorEmitido="log"></richTextInput> -->
        </div>  
        
        <!-- 4.9.3 Distribuição da carga horária em cada ambiente.  -->
        <div v-if="!form_especifico_lab" class="row mt-4 mb-4">
            <itemInput code="item_4_9_3" :id_curso="id_curso_atual" titulo="4.9.3 Distribuição da carga horária em cada ambiente" v-model="formulario_especifico.item_4_9_3" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_4_9_3" :id_curso="id_curso_atual" titulo="4.9.3 Distribuição da carga horária em cada ambiente" v-model="formulario_especifico.item_4_9_3" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 5. Instalações e equipamentos -->
        <div class="row mb-2 mt-4">
            <h2>5. Instalações e equipamentos</h2>
            <hr>
        </div>

        <!-- 5.2 Relação de laboratórios -->
        <div v-if="form_especifico_lab" class="row mt-4 mb-4">
            <tabelaInput code="item_5_2" :id_curso="id_curso_atual" titulo="5.2 Relação de laboratórios" v-model="formulario_especifico.item_5_2" @valorEmitido="log" :array_campus_oferta="campus_oferta" :campus_variavel="true"></tabelaInput>
        </div>

        <!-- 5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas -->
        <div v-if="form_especifico_lab" class="row mt-4 mb-4">
            <tabelaInput code="item_5_3" :id_curso="id_curso_atual" titulo="5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas" v-model="formulario_especifico.item_5_3" @valorEmitido="log"></tabelaInput>
        </div>

        <!-- 5.6 Biblioteca -->
        <div v-if="!form_especifico_lab">
            <h6><strong>5.6 Biblioteca</strong></h6>
            <!-- Pré-texto especial solicitado pela biblioteca -->
            <itemInput code="item_5" :id_curso="id_curso_atual" titulo="" v-model="formulario_especifico.item_5" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_5" :id_curso="id_curso_atual" titulo="" v-model="formulario_especifico.item_5" @valorEmitido="log"></richTextInput> -->
            <div class="row mb-4">
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio disabled v-model="bib_select_radio" :aria-describedby="ariaDescribedby" name="some-radios" value="Física">Física</b-form-radio>
                    <b-form-radio disabled v-model="bib_select_radio" :aria-describedby="ariaDescribedby" name="some-radios" value="Virtual">Virtual</b-form-radio>
                    <b-form-radio disabled v-model="bib_select_radio" :aria-describedby="ariaDescribedby" name="some-radios" value="Ambas">Ambas</b-form-radio>
                </b-form-group>
            </div> 
        </div>
        

        <!-- 5.7 Acervo bibliográfico do curso -->
        <div v-if="!form_especifico_lab && bibliografia_unidads_curriculares" class="row mt-4 mb-4">
            <h6><strong>5.7 Acervo bibliográfico do curso</strong></h6>
            <h6>Livros das referências bibliográficas (Básica/Complementar) das unidades curriculares cadastradas:</h6>
            <b-table-simple small responsive>
                <colgroup><col><col></colgroup>
                <b-thead>
                    <b-tr>
                        <b-th colspan="2">Título</b-th>
                        <b-th colspan="3">Acesso</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="item in bibliografia_unidads_curriculares" :key="item.id">
                        <b-td colspan="2">{{item}}</b-td>
                        <b-td colspan="3">Virtual</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> 
        </div>

        <!-- 6. Corpo Docente e Tutorial, Coordenador Pedagógico e Pessoal Técnico-Administrativo -->
        <div v-if="!form_especifico_lab" class="row mt-4">
            <h2>6. Corpo Docente e Tutorial, Coordenador Pedagógico e Pessoal Técnico-Administrativo</h2>
            <hr>
        </div>

        <!-- 6.1. Corpo Docente -->
        <div v-if="!form_especifico_lab" class="row mt-4 mb-4">
            <tabelaInputRh code="item_6_1" :id_curso="id_curso_atual" :campus_oferta="campus_oferta" titulo="6.1. Corpo Docente" v-model="formulario_especifico.item_6_1" @valorEmitido="log"></tabelaInputRh>
        </div>
        
        <!-- 6.2. Corpo Tutorial -->
        <div v-if="!form_especifico_lab" class="row mt-4 mb-4">
            <tabelaInputRh code="item_6_2" :id_curso="id_curso_atual" :campus_oferta="campus_oferta" titulo="6.2. Corpo Tutorial" v-model="formulario_especifico.item_6_2" @valorEmitido="log"></tabelaInputRh>
        </div>

        <!-- 6.3. Coordenador Pedagógico do Curso -->
        <div v-if="!form_especifico_lab" class="row mt-4 mb-4">
            <tabelaInputRh code="item_6_3" :id_curso="id_curso_atual" :campus_oferta="campus_oferta" titulo="6.3. Coordenador Pedagógico do Curso" v-model="formulario_especifico.item_6_3" @valorEmitido="log"></tabelaInputRh>
        </div>

        <!-- 6.4. Pessoal Técnico Administrativo -->
        <div v-if="!form_especifico_lab" class="row mt-4 mb-4">
            <tabelaInputRh code="item_6_4" :id_curso="id_curso_atual" :campus_oferta="campus_oferta" titulo="6.4. Pessoal Técnico Administrativo" v-model="formulario_especifico.item_6_4" @valorEmitido="log"></tabelaInputRh>
        </div>

        <!-- 10. Link das Informações e Imagens (Buriola) -->
        <div v-if="!form_especifico_lab" class="row mb-4 mt-4 dropdown">
            <h2>10. Link das Informações e Imagens (Buriola)</h2>
            <hr>

            <div class="row mb-3">
                <!-- Seletor de Campus -->
                <div class="col-5 mt-1 mb-1">
                    campus_selecionado: {{link_item_10_campus_selecionado}} <br>
                    <b-form-select placeholder="Escolha um campus" v-model="link_item_10_campus_selecionado" :options="campus_oferta"></b-form-select>
                </div>
            </div>
            
            <!-- Botão Editar
            <div class="col" style="text-align: right;">
                <b-button v-b-modal="'modal-item_10'" variant="link" style="text-decoration: none"> 
                <b-icon icon="pencil-square"></b-icon>Editar</b-button>
            </div> -->

            <!-- Texto atual
            <div class="col-12">
                <p v-if="formulario_especifico.item_10" style="text-align: justify">
                    {{formulario_especifico.item_10}}
                    <div v-if="esteLinkEstaNoCampus(formulario_especifico.item_10[0])">

                    </div>
                </p>
                <p v-else class="sem-texto" style="padding: 10px; font-style: italic; background: #e8e8e8;">
                    Não há nenhum texto cadastrado. Clique em editar para inserir.
                </p>
            </div> -->
            
            <!-- Modal acionado -->
            <b-modal size="lg" id="modal-item_10" :title="'10. Link das Informações e Imagens | ' + link_item_10_campus_selecionado">
                <b-form-group label="" label-for="modal-input-item_10">
                    <b-form-input v-on:keyup.enter="changeFirebaseValue" id="modal-input-item_10" v-model="link_item_10"></b-form-input>
                </b-form-group>     

                <template #modal-footer>
                    <b-button size="sm" variant="primary" @click="change_item_10()">
                        Salvar
                    </b-button>  
                </template> 
            </b-modal>
        </div>
         
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
import itemInput from '@/views/teste_nadir/components/itemInput.vue'
import tabelaInput from '@/views/teste_nadir/components/tabelaInput.vue'
import tabelaInputRh from '@/views/teste_nadir/components/tabelaInputRh.vue'
// import richTextInput from '@/views/teste_nadir/components/richTextInput.vue'
import addTopicItem from '@/views/teste_nadir/components/addTopicItem.vue'


// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: { 
        itemInput,
        tabelaInput,
        tabelaInputRh,
        // richTextInput,
        addTopicItem
    },
    data() {
        return {
            id_curso_atual: this.$route.params.id_firebase,
            formulario_especifico: [],  
            responsavel: '',
            nome_curso: '',
            conteudista: '',
            eixo_curso: '',
            selected_campus_item_6: '',
            campus_oferta: [],
            campus_infos_cadastro: [],
            form_especifico_campus: false,
            form_especifico_lab: true,
            bib_select_radio: 'Ambas',
            // Dados Gerais do Curso Técnico de Nível Médio
            dg_gerais_curso_tecnico: {
                denominacao: '',
                carga_horaria_curso: '',
                carga_horaria_estagio: '',
                modalidade_oferta: '',
                forma_oferta: '',
            },
            // Certificações intermediárias (se houver): <Repetir para cada uma>
            certificacoes_intermediarias: [
                // {
                //     denominacao: '',
                //     carga_horaria: ''
                // },
                // {
                //     denominacao: '',
                //     carga_horaria: ''
                // }
            ],
            // Organização da Oferta
            organizacao_oferta: {
                periodicidade_matriculas: '',
                turnos_funcionamento: '',
                prazo_min_integralizacao: '',
                prazo_max_integralizacao: '',
                num_turmas_matutino: '',
                num_turmas_vespertino: '',
                num_turmas_noturno: '',
                vagas_por_turmas_matutino: '',
                vagas_por_turmas_vespertino: '',
                vagas_por_turmas_noturno: '',
                vagas_totais_anuais_solicitadas: ''
            },
            link_item_10: '',
            link_item_10_campus_selecionado: null,
        }
    },
    computed: {
        bibliografia_unidads_curriculares: function(){
            let bibliografia_basica = []
            if(this.formulario_especifico.item_4_2_1){
                this.formulario_especifico.item_4_2_1.forEach(x => {
                    x.bibliografia_basica.forEach( y => bibliografia_basica.push(y))
                })
                console.log(bibliografia_basica) 
            }
            return bibliografia_basica
        },

        item_10_para_campus_selecionado(){
            // Aqui vamos definir um array quandos os dados chegam, pq eles vão se perderm na renderização filtrada do v-for
            if(this.link_item_10_campus_selecionado !== null){
                return this.value.filter(x => x.campus == this.link_item_10_campus_selecionado)
            } else {
                return []
            }
        },
    },
    methods:{
        log(value){
            console.log('Item atualizado', value)
            // Após o elemento concluir a inserção de dados no firebase, atualiza os dados
            this.atualizarDados()
        },

        atualizarDados(){
            db.collection("/nadir").doc(this.$route.params.id_firebase)
            .get()
            .then((doc) => {
                this.formulario_especifico = doc.data().formulario_especifico
                this.nome_curso = doc.data().nome_curso
                this.conteudista = doc.data().conteudista
                this.eixo_curso = doc.data().eixo_curso
                this.campus_oferta = doc.data().campus_oferta
                // Dados Gerais do Curso Técnico de Nível Médio
                this.dg_gerais_curso_tecnico.denominacao = doc.data().dg_gerais_curso_tecnico.denominacao
                this.dg_gerais_curso_tecnico.carga_horaria_curso = doc.data().dg_gerais_curso_tecnico.carga_horaria_curso
                this.dg_gerais_curso_tecnico.carga_horaria_estagio = doc.data().dg_gerais_curso_tecnico.carga_horaria_estagio
                this.dg_gerais_curso_tecnico.modalidade_oferta = doc.data().dg_gerais_curso_tecnico.modalidade_oferta
                this.dg_gerais_curso_tecnico.forma_oferta = doc.data().dg_gerais_curso_tecnico.forma_oferta
                // Certificações intermediárias (se houver):
                this.certificacoes_intermediarias = doc.data().certificacoes_intermediarias
                // Organização da Oferta
                this.organizacao_oferta.periodicidade_matriculas = doc.data().organizacao_oferta.periodicidade_matriculas
                this.organizacao_oferta.turnos_funcionamento = doc.data().organizacao_oferta.turnos_funcionamento
                this.organizacao_oferta.prazo_min_integralizacao = doc.data().organizacao_oferta.prazo_min_integralizacao
                this.organizacao_oferta.prazo_max_integralizacao = doc.data().organizacao_oferta.prazo_max_integralizacao
                this.organizacao_oferta.num_turmas_matutino = doc.data().organizacao_oferta.num_turmas_matutino
                this.organizacao_oferta.num_turmas_vespertino = doc.data().organizacao_oferta.num_turmas_vespertino
                this.organizacao_oferta.num_turmas_noturno = doc.data().organizacao_oferta.num_turmas_noturno
                this.organizacao_oferta.vagas_por_turmas_matutino = doc.data().organizacao_oferta.vagas_por_turmas_matutino
                this.organizacao_oferta.vagas_por_turmas_vespertino = doc.data().organizacao_oferta.vagas_por_turmas_vespertino
                this.organizacao_oferta.vagas_por_turmas_noturno = doc.data().organizacao_oferta.vagas_por_turmas_noturno
                this.organizacao_oferta.vagas_totais_anuais_solicitadas = doc.data().organizacao_oferta.vagas_totais_anuais_solicitadas

                console.log(this.formulario_especifico)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        esteLinkEstaNoCampus(link){
            if(this.link_item_10_campus_selecionado == link.campus){
                return link
            } else {
                return false
            }
        },

        change_item_10(){
            console.log('Campus:', this.link_item_10_campus_selecionado)
            console.log('Valor antigo', this.formulario_especifico.item_10)
            console.log('Valor novo', this.link_item_10)

            let link_item_10 = {
                link: this.link_item_10,
                campus: this.link_item_10_campus_selecionado
            }

            let obj_firebase
            if (!this.formulario_especifico.item_10){
                // Se o array não existir, crie um
                obj_firebase = []
            } else {
                obj_firebase = this.formulario_especifico.item_10
            }
            obj_firebase.push(link_item_10)
            console.log(obj_firebase)

            let doc_name = this.id_curso_atual
            let path_item = {'formulario_especifico.item_10': obj_firebase}
            let colection = '/nadir'

            db.collection(colection).doc(doc_name)
            .update(path_item)
            .then(() => {
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide("modal-item_10")
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        }

    },
    mounted() {
        if(this.$route.params.id_firebase){
            this.atualizarDados()
            // Estamos editando o formulário específico do campus?
            this.$route.name == 'form_esp_uninove_lab' ? this.form_especifico_lab = true : this.form_especifico_lab = false
        } else {
            alert('Nenhum curso encontrado para o ID especificado')
        }
    }
}
</script>

<style scoped>
*{
    text-align: justify;
}
.dropdown{
    border: 1px solid red
}
ol, ul {
    list-style: decimal;
    margin-bottom: unset;
}
p{
    margin-bottom: unset !important;
}
</style>