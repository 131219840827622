<template>
    <div class="row mt-2 mb-2">

        <!-- label-->
        <div class="col-10" style="align-self: end;">
            <!-- <h6><strong>{{titulo}} | {{getCampusNome(campus_selecionado)}} </strong></h6> -->
            <h6><strong>{{titulo}}</strong></h6>

        </div>
        
        <div class="row mb-3">
            <!-- Seletor de Campus -->
            <!-- <div class="col-5 mt-1 mb-1">
                <b-form-select placeholder="Escolha um campus" v-model="campus_selecionado" :options="campus_oferta"></b-form-select>
            </div> -->

            <!-- Botão Editar -->
            <div class="col" style="text-align: right;">
                <b-button v-b-modal="'modal-'+code" variant="link" style="text-decoration: none"> 
                <b-icon icon="plus-square"></b-icon> Adicionar</b-button>
            </div>
        </div>

        <!-- Tabela -->
        <div v-if="value" class="col-12">
            <div v-for="(item, id_cadastro) in value" :key="item.id_cadastro">
                <b-table-simple outlined small responsive>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="5">{{item.nome}}
                                <b-button @click="editUnidade(id_cadastro)" variant="link" style="text-decoration: none"> 
                                <b-icon icon="pencil-square"></b-icon>Editar</b-button>
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td colspan="1"><strong>Formação principal:</strong></b-td>
                            <b-td colspan="4">
                                <ol>
                                    <li>{{item.formacao_principal}}</li>
                                </ol>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="1"><strong>Formações Adicionais:</strong></b-td>
                            <b-td colspan="4">
                                <ol>
                                    <li v-for="formacao in item.formacao_adicionais" :key="formacao.id">{{formacao}}</li>
                                </ol>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="1"><strong>Unidades curriculares de atuação:</strong></b-td>
                            <b-td colspan="4">
                                <ol>
                                    <li v-for="unidades in item.unidades_curriculares" :key="unidades.id">{{unidades}}</li>
                                </ol>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="5"><strong>Regime de trabalho:</strong> {{item.regime_trabalho}} </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="2"><strong>Tempo de atividade na instituição:</strong> {{item.temp_atividade_instituicao}}</b-td>
                            <b-td colspan="3"><strong>Tempo de experiência profissional na área do curso técnico ou educação:</strong> {{item.temp_experiencia_prof}}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple> 
            </div>
        </div>
        <div v-else>
            <p class="sem-texto">
                Não há nenhum item cadastrado. Clique em ADICIONAR para inserir.
            </p>
        </div>
        
        <!-- Modal acionado -->
        <b-modal size="lg" :id="'modal-'+code" :title="titulo" @hidden="resetModal">
            <!-- {{valor_provisorio}} -->
            <b-form-group label="Nome:" :label-for="'modal-input-'+code+'nome'">
                <b-form-input :id="'modal-input-'+code+'nome'" v-model="valor_provisorio.nome" rows="3" max-rows="20"></b-form-input>
            </b-form-group>

            <b-form-group label="formacao_principal:" :label-for="'modal-input-'+code+'formacao_principal'">
                <b-form-input :id="'modal-input-'+code+'formacao_principal'" v-model="valor_provisorio.formacao_principal" rows="3" max-rows="20"></b-form-input>
            </b-form-group>  

            <label :for="'modal-input-'+code+'formacao_adicionais'">Formações Adicionais. (Escreva as formações separadas por vírgula):</label>
            <b-form-tags
            :id="'modal-input-'+code+'formacao_adicionais'"
            v-model="valor_provisorio.formacao_adicionais"
            separator=",;"
            placeholder=""
            no-add-on-enter
            ></b-form-tags>

            <label :for="'modal-input-'+code+'formacao_adicionais'">Unidades curriculares de atuação. (Escreva as formações separadas por vírgula) :</label>
            <b-form-tags
            :id="'modal-input-'+code+'formacao_adicionais'"
            v-model="valor_provisorio.unidades_curriculares"
            separator=",;"
            placeholder=""
            no-add-on-enter
            ></b-form-tags>

            <b-form-group label="Regime de trabalho:" :label-for="'modal-input-'+code+'regime_trabalho'">
                <b-form-select v-model="valor_provisorio.regime_trabalho" :options="opcoes_trabalho"></b-form-select>
                <!-- <b-form-input :id="'modal-input-'+code+'regime_trabalho'" v-model="valor_provisorio.regime_trabalho"></b-form-input> -->
            </b-form-group>

            <b-form-group label="Tempo de atividade Na instituição:" :label-for="'modal-input-'+code+'temp_atividade_instituicao'">
                <b-form-input :id="'modal-input-'+code+'temp_atividade_instituicao'" v-model="valor_provisorio.temp_atividade_instituicao"></b-form-input>
            </b-form-group>

            <b-form-group label="Tempo de experiência profissional na área do curso técnico ou educação:" :label-for="'modal-input-'+code+'temp_experiencia_prof'">
                <b-form-input :id="'modal-input-'+code+'temp_experiencia_prof'" v-model="valor_provisorio.temp_experiencia_prof"></b-form-input>
            </b-form-group> 

            <template v-if="modal_item_edit" #modal-footer>
                    <b-button size="sm" variant="danger" @click="deleteEditUnidade()">
                        Deletar
                    </b-button>
                    <b-button size="sm" variant="primary" @click="salvarEditUnidade()">
                        Salvar
                    </b-button>  
                </template>
                <template v-else #modal-footer>
                    <b-button size="sm" variant="primary" @click="Add()">
                        Adicionar
                    </b-button>  
                </template> 
        </b-modal>

    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    name: 'tabelaInputRh',
    props: ['titulo', 'code', 'value', 'id_curso', 'campus_oferta'],
    data() {
        return {
            valor_provisorio: {
                nome: '',
                formacao_principal: '',
                formacao_adicionais: [],
                unidades_curriculares: [],
                regime_trabalho: '',
                temp_atividade_instituicao: '',
                temp_experiencia_prof: '',
            },
            campus_nome_selecao: '',
            campus_selecionado: '',
            campus_infos_cadastro: [],
            modal_item_edit: false,
            cadastro_selecionado: '',
            opcoes_trabalho: [
                { value: 'Integral', text: 'Integral' },
                { value: 'Parcial', text: 'Parcial' },
                { value: 'Horista', text: 'Horista' },
            ]
        }
    },
    computed: {
        array_formacao: function(){
            let bibliografia_basica = []
            this.formulario_especifico.item_4_2_1.forEach(x => {
                x.bibliografia_basica.forEach( y => bibliografia_basica.push(y))
            })
            console.log(bibliografia_basica)
            // array_checkboxes = this.campus_uninove.map(x => { return { text: x.nome, value: x.id }})    
            return bibliografia_basica
        },
        cadastros_para_campus_selecionado: function(){
            if(this.campus_selecionado){
                return this.value.filter(x => x.campus == this.campus_selecionado)
            } else {
                return []
            }
        },
    },
    methods: {
        resetModal() {
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio = {
                nome: '',
                formacao_principal: '',
                formacao_adicionais: [],
                unidades_curriculares: [],
                regime_trabalho: '',
                temp_atividade_instituicao: '',
                temp_experiencia_prof: '',
            }
        },

        getCampusNome(target_id){
            if(target_id){
                return this.campus_infos_cadastro.find(x => x.id == target_id).nome
            }
        },

        getCampusUninove(){
            // Solicitando os campus
            db.collection("/campus_uninove")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let campus = doc.data()
                    this.campus_infos_cadastro.push(campus)
                    
                });
                // this.campus_nome_selecao = this.campus_oferta.map(x => this.getCampusNome(x))
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        getSelectedCampusDados(campus_id){
            return this.dados.find(x => x.campus == campus_id)
        },

        Add(){
            let new_obj = {
                nome: this.valor_provisorio.nome,
                formacao_principal: this.valor_provisorio.formacao_principal,
                formacao_adicionais: this.valor_provisorio.formacao_adicionais,
                unidades_curriculares: this.valor_provisorio.unidades_curriculares,
                regime_trabalho: this.valor_provisorio.regime_trabalho,
                temp_atividade_instituicao: this.valor_provisorio.temp_atividade_instituicao,
                temp_experiencia_prof: this.valor_provisorio.temp_experiencia_prof,
                campus: this.campus_selecionado
            }

            if (this.code == 'item_6_3'){
                this.value = []
            }

            let array_firebase
            if (!this.value){
                // Se o array não existir, crie um
                this.value = []
                this.value.push(new_obj)
            } else {
                array_firebase = this.value
                this.value.push(new_obj)
            }
            

            // console.log("Objeto a ser cadastrado: ", array_firebase)
            // console.log("No curso: ", this.id_curso)
            // console.log(this.value)

            let doc_name
            let path_item
            let colection

            doc_name = this.id_curso
            path_item = {[`formulario_especifico.${this.code}`]: array_firebase}
            colection = '/nadir'

            console.log(`mudando o ${this.code} e adicionando o valor`, array_firebase)

            console.log(doc_name, path_item, colection)

            db.collection(colection).doc(doc_name)
            .update(path_item)
            .then(() => {
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide('modal-'+ this.code)
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        editUnidade(id_cadastro){
            this.modal_item_edit = true
            this.cadastro_selecionado = id_cadastro
            this.valor_provisorio.nome = this.value[id_cadastro].nome,
            this.valor_provisorio.formacao_principal = this.value[id_cadastro].formacao_principal,
            this.valor_provisorio.formacao_adicionais = this.value[id_cadastro].formacao_adicionais,
            this.valor_provisorio.unidades_curriculares = this.value[id_cadastro].unidades_curriculares
            this.valor_provisorio.regime_trabalho = this.value[id_cadastro].regime_trabalho,
            this.valor_provisorio.temp_atividade_instituicao = this.value[id_cadastro].temp_atividade_instituicao
            this.valor_provisorio.temp_experiencia_prof = this.value[id_cadastro].temp_experiencia_prof
            this.$bvModal.show('modal-'+this.code)
        },

        deleteEditUnidade(){
            if(confirm('Tem certeza que desejas escluir este funcionário: ' + this.value[this.cadastro_selecionado].nome + ' ? Todo o preenchimento será perdido.')){
                this.value.splice(this.cadastro_selecionado, 1)

                db.collection('/nadir').doc(this.id_curso)
                .update({[`formulario_especifico.${this.code}`]: this.value})
                .then(() => {
                    this.$emit('valorEmitido', this.value)
                this.$bvModal.hide('modal-'+this.code)
                this.resetModal()
                })
                .catch((error) => {
                    alert('Erro, verifique o console')
                    console.error("Error updating document: ", error);
                });
            }
        },

        salvarEditUnidade(){
            let id_cadastro = this.cadastro_selecionado
            this.value[id_cadastro].nome = this.valor_provisorio.nome
            this.value[id_cadastro].formacao_principal = this.valor_provisorio.formacao_principal
            this.value[id_cadastro].formacao_adicionais = this.valor_provisorio.formacao_adicionais
            this.value[id_cadastro].unidades_curriculares = this.valor_provisorio.unidades_curriculares
            this.value[id_cadastro].regime_trabalho = this.valor_provisorio.regime_trabalho
            this.value[id_cadastro].temp_atividade_instituicao = this.valor_provisorio.temp_atividade_instituicao
            this.value[id_cadastro].temp_experiencia_prof = this.valor_provisorio.temp_experiencia_prof

            // console.log('Item a ser alterado:', this.value)

            db.collection('/nadir').doc(this.id_curso)
            .update({[`formulario_especifico.${this.code}`]: this.value})
            .then(() => {
                this.$emit('valorEmitido', this.value)
                this.$bvModal.hide('modal-'+this.code)
                this.resetModal()
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },
        
    },
    mounted(){
        // this.getCampusUninove()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input{
    width: -webkit-fill-available;
}

.sem-texto{
    padding: 10px;
    font-style: italic;
    background: #e8e8e8;
}

ol, ul {
    list-style: decimal;
    margin-bottom: unset;
}
</style>
